// extracted by mini-css-extract-plugin
export var section = "Integration-module--section--3aFGs";
export var container = "Integration-module--container--2zTeB";
export var morePadding = "Integration-module--morePadding--3nVXK";
export var featuresWrapper = "Integration-module--featuresWrapper--1oGk2";
export var featureItem = "Integration-module--featureItem--3yjRO";
export var content = "Integration-module--content--3bNP-";
export var olList = "Integration-module--olList--1tkbm";
export var olListItem = "Integration-module--olListItem--C7ggu";
export var ulList = "Integration-module--ulList--1UGyM";
export var ulListItem = "Integration-module--ulListItem--1mEX8";
export var setupListHint = "Integration-module--setupListHint--1mz6s";
export var contactUs = "Integration-module--contactUs--1fg4i";